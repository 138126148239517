








import { Component, Vue } from "vue-property-decorator";
import DetalleAdopcion from "@/components/Adopciones/DetalleAdopcion.vue";

@Component({
  components: {
    DetalleAdopcion
  }
})
export default class DetalleAdopcionView extends Vue {}
