









































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DetalleAdopcion extends Vue {
  private horizontalPercentageInteres = 0;
  private nombreWeb: any = "";
  private idBuscar: any = "";
  private dataMascota: any = [];
  private nombreTipo: any = "";
  private nombreBreads: any = "";
  private cargarPagina = false;
  private detalleGaleria = false;
  private slide = "";
  // galeria
  private slideIndex: number = 1;

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.idBuscar = this.$router.currentRoute.params.id;
      this.getInfoMascotas();
      this.linkCanonical(this.nombreWeb);
    }
  }
  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://www.quilpue.cl/adopcion/"+this.idBuscar+'/'+this.nombreWeb
    document.head.appendChild(canonicalLink);
  }

  private getInfoMascotas() {
    this.$axios
      .get("mascotas/perfil?id=" + this.idBuscar)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.dataMascota = res.data;
        if (this.dataMascota.foto) {
          this.dataMascota.foto = base + this.dataMascota.foto;
        } else {
          this.dataMascota.foto =
            this.dataMascota.id_categoria == 96
              ? require("@/assets/gato.png")
              : require("@/assets/perro.png");
        }

        for (let i = 0; i < this.dataMascota.imagenes.length; i++) {
          if (
            this.dataMascota.imagenes[i].imagen &&
            this.dataMascota.imagenes[i].imagen != ""
          ) {
            this.dataMascota.imagenes[i].imagen =
              base + this.dataMascota.imagenes[i].imagen;
            this.dataMascota.imagenes[i].imagen;
          }
        }

        // //MetaData generica
        document.title =
          this.dataMascota.nombre + " - Municipalidad de Quilpué";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", this.dataMascota.descripcion);
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.dataMascota.foto
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.dataMascota.nombre + " - Municipalidad de Quilpué"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", this.dataMascota.descripcion);
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.dataMascota.foto);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.dataMascota.nombre + " - Municipalidad de Quilpué"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", this.dataMascota.descripcion);
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.dataMascota.foto);

        this.cargarPagina = true;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private toLink(item: any, target: any) {
    if (item && item.trim() !== "") {
      if (!item.startsWith("http://") && !item.startsWith("https://")) {
        item = "https://" + item;
      }
      window.open(item, target);
    }
  }

  // galeria

  private updated() {
    this.showSlides(this.slideIndex);
  }

  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      if (!this.$q.screen.xs) {
        var dots = document.getElementsByClassName("dot");
        for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
        }
        dots[this.slideIndex - 1].className += " active";
      }
      (slides[this.slideIndex - 1] as any).style.display = "flex";
    }
  }

  private seleccionarAbrirModal(index) {
    this.slideIndex = index;
    this.detalleGaleria = true;
  }

  // nuevo slider
  // SCROLL CARROUSEL GALERIA
  private scroll(direccion: string) {
    // console.log(this.$refs.scrollInteres);
    if (this.$refs.scrollInteres as any) {
      const size = (this.$refs.scrollInteres as any).scrollSize;
      const position = (this.$refs.scrollInteres as any).scrollPosition;
      // console.log("size", size);
      //       console.log("position", position);

      if (direccion == "derecha") {
        if (this.horizontalPercentageInteres >= 0.9868) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position + 230,
            150
          );
        }
      } else {
        if (position - 90 < 0) {
          if (position > 0) {
            (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
          } else {
            (this.$refs.scrollInteres as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position - 230,
            150
          );
        }
      }
    }
  }

  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }

  public enviarEmail(item: any) {
    const subject = "Consulta sobre Mascota";
    const body = `Hola,Estoy interesado(a) en la siguiente mascota:
    Nombre: ${item.nombre}
    Página: https://www.quilpue.cl/adopcion/${item.id}/${item.nombre_web}
    Por favor, envíeme más detalles.
    Gracias,
    `;

    const mailtoLink = `mailto:correoprueba@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  }

  private llamar() {
    window.open("tel:+56999999999");
  }

  private mensaje(item:any) {
    const body = `Hola, Estoy interesado(a) en la siguiente mascota:
  Nombre: ${item.nombre}
  Página: https://www.quilpue.cl/adopcion/${item.id}/${item.nombre_web}
  Por favor, envíeme más detalles.
  Gracias,`;

    const encodedMessage = encodeURIComponent(body);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      window.open(`https://wa.me/56999999999?text=${encodedMessage}`);
    } else {
      window.open(
        `https://web.whatsapp.com/send?phone=56999999999&text=${encodedMessage}`
      );
    }
  }
}
